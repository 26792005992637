import { IAppSessionSettings } from '../dataProvider/IAppSessionSettings';
import Footer from '../components/Footer';
import { getToken } from '../SharedCommon/utils';

import { _dataProvider } from '../dataProvider/DataProvider';
import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { pwaUrl, SERVER_URL } from '../SharedCommon/utils';
import i18n from '../i18n';

import { getOrgLogo } from '../SharedCommon/OrgList';

interface IAppDownloadProps {
  saveSettings: any;
  signInState: boolean;
  lang: string;
  settings: IAppSessionSettings;
  HeadClickCount: number;
}

class AppDownload extends React.Component<IAppDownloadProps> {
  private sessionIdx: number;
  private merge: boolean;

  constructor(props: IAppDownloadProps) {
    super(props);
    this.merge = true;
    this.sessionIdx = 0;

    this.state = {};
    console.log('navigator.appVersion: ', window.navigator.appVersion);
  }

  public componentDidMount() {
    console.log('Mounted');
  }
  //          className="iDigestLogo"
  render(): React.ReactNode {
    const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
    return (
      <>
        <div
          style={{
            display: 'block',
            margin: 'auto',
            width: '50%',
            marginTop: '100px'
          }}>
          <img src={IDigestLogo} alt='IDigest Logo' style={{ display: 'block', height: '48px', margin: 'auto' }}></img>
          <h3 style={{ margin: 'auto', textAlign: 'center', marginTop: '20px' }}>
            {i18n.t('LinkToInstall')}
            <p>{SERVER_URL}/install</p>
            <a href={`${SERVER_URL}/install`} style={{ marginRight: 0 }}>
              <DefaultButton
                styles={{
                  root: {
                    marginTop: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '25px'
                  }
                }}>
                {i18n.t('DownloadInstall')}
              </DefaultButton>
            </a>
            <br></br>
            <br></br>
            <br></br>
            {window.navigator.userAgent.indexOf('Mac') === -1 && (
              <>
                {i18n.t('PWAInstallNote')}
                <br></br>
                <a
                  href=''
                  style={{ marginRight: 0 }}
                  onClick={() => {
                    window.open(pwaUrl + '?tk=' + getToken());
                    return false;
                  }}></a>
              </>
            )}
          </h3>
        </div>
        <Footer />
      </>
    );
  }
}

export default AppDownload;
